export const ENV = 'testing';
export const PAGE_TITLE = "Registro de Personas - iaCombustibles";
export const API_ENDPOINT = 'https://dominus.iapropiada.com/';
export const TERMS_TEXT = 'Sus datos personales han sido y están siendo tratados conforme con nuestra Política de Tratamiento de Datos Personales.  Términos de uso: *Ley de Protección de Datos Personales: “La autorización suministrada en el presente formulario faculta a Ingeniería Apropiada SAS para que dé a sus datos aquí recopilados el tratamiento señalado en la “Política de Privacidad para el Tratamiento de Datos Personales” de Ingeniería Apropiada SAS. Con la expedición de la ley 1581 de 2012 y el Decreto 1377 de 2013, se desarrolla el principio constitucional que tiene el titular de los datos para, en cualquier momento, solicitar que la información sea modificada, actualizada o retirada de las bases de datos de Ingeniería Apropiada SAS.';
export const APP_COLORS =  {
    primary:"#0098cc",
    secondary:"#04B3FF",
    dark:"#000000",
    light:"#f2f2f2",
    shade:"#cccccc"
};
